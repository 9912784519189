<route>
{
  "meta": {
    "auth": "statisticsComposite"
  }
}
</route>

<template>
  <div v-loading="loading" element-loading-text="正在查询，请稍候" element-loading-spinner="el-icon-loading">
    <el-card class="searchContainer">
      <div class="desc">筛选查询</div>
      <el-row :gutter="20" class="query">
        <el-col :span="8">
          <el-date-picker
            value-format="timestamp"
            v-model="searchValue"
            type="datetimerange"
            :default-time="['00:00:00', '23:59:59']"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-col>
        <el-col :span="8">
          <div v-if="$store.getters['user/isAllArea']">
            <span class="text">城市</span>
            <el-select class="city" placeholder="请选择城市" v-model="areaId">
              <el-option label="全国" :value="-1"></el-option>
              <el-option
                v-for="item in $store.getters['user/userAreaInfoListWithoutGlobal']"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </el-col>

        <el-col :span="8">
          <el-button type="info" @click="resetSearch()">重置</el-button>
          <el-button type="primary" @click="handleSearch()"> 搜索</el-button></el-col
        >
      </el-row>
    </el-card>
    <div style="display:flex">
      <div style=" flex: 1 1 400px;">
        <el-card class="tableContainer" style="display:inline-block;height: 400px;    width: calc(100% - 20px) ;">
          <div class="title">商城销售额</div>
          <div>
            <el-row :gutter="20">
              <el-col :span="6">
                <bm-pie
                  class="chart"
                  height="300px"
                  :config="allSharingMoneyConfig1"
                  :legendFormat="moneyLegendConfig"
                ></bm-pie>
              </el-col>
              <el-col :span="6">
                <bm-pie
                  class="chart"
                  height="300px"
                  :config="platformSharingMoneyConfig1"
                  :legendFormat="moneyLegendConfig"
                >
                </bm-pie>
              </el-col>
              <el-col :span="6">
                <bm-pie
                  class="chart"
                  height="300px"
                  :config="businessSharingMoneyConfig1"
                  :legendFormat="moneyLegendConfig"
                ></bm-pie>
              </el-col>
              <el-col :span="6">
                <bm-pie
                  class="chart"
                  height="300px"
                  :config="businessFoundConfig"
                  :legendFormat="moneyLegendConfig10"
                ></bm-pie>
              </el-col>
            </el-row></div
        ></el-card>
        <el-card class="tableContainer" style="display:inline-block;height: 400px;    width:  calc(100% - 20px);">
          <div class="title">商城已核销额</div>
          <div>
            <el-row :gutter="20">
              <el-col :span="8">
                <bm-pie
                  class="chart"
                  height="300px"
                  :config="allSharingMoneyConfig"
                  :legendFormat="sharingLegendConfig"
                ></bm-pie>
              </el-col>
              <el-col :span="8">
                <bm-pie
                  class="chart"
                  height="300px"
                  :config="platformSharingMoneyConfig"
                  :legendFormat="sharingLegendConfig"
                >
                </bm-pie>
              </el-col>
              <el-col :span="8">
                <bm-pie
                  class="chart"
                  height="300px"
                  :config="businessSharingMoneyConfig"
                  :legendFormat="sharingLegendConfig"
                ></bm-pie>
              </el-col>
            </el-row></div
        ></el-card>
      </div>

      <el-card class="tableContainer" style="display:inline-block;width:  400px;height: 844px;">
        <div class="title">分销佣金</div>

        <div class="distribution">
          <div class="number">{{ distribution.readyIncome }}</div>
          <div class="text">已核销分销佣金</div>
        </div>
        <div class="distribution">
          <div class="number">{{ (distribution.notIncomeYet + distribution.readyIncome).toFixed(2) }}</div>
          <div class="text">全部分销佣金</div>
        </div>
      </el-card>
    </div>

    <div style="display:flex">
      <el-card class="tableContainer " style="display:inline-block;width: 100%;height:350px">
        <div class="title">订单统计</div>
        <div class="item">
          <bm-tween
            class="number"
            :style="{ color: $store.getters['global/randomColor']() }"
            :value="orderCol.memberOrderCount"
          ></bm-tween>
          <div class="text">订单总数</div>
        </div>
        <div class="item">
          <bm-tween
            class="number"
            :style="{ color: $store.getters['global/randomColor']() }"
            :value="orderCol.totalSellMoney"
          ></bm-tween>
          <div class="text">销售总额 (元)</div>
        </div>
        <div class="item">
          <bm-tween
            class="number"
            :style="{ color: $store.getters['global/randomColor']() }"
            :value="orderCol.totalValidOrderCount"
          ></bm-tween>
          <el-tooltip
            class="item"
            effect="dark"
            style="width: 100%;"
            content="订单中一个或多个核销码被核销，叫做有效订单总数"
            placement="bottom"
          >
            <div class="text">有效订单总数</div>
          </el-tooltip>
        </div>
        <div class="item">
          <bm-tween
            class="number"
            :style="{ color: $store.getters['global/randomColor']() }"
            :value="orderCol.totalValidOrderMoney"
          ></bm-tween>
          <el-tooltip
            class="item"
            effect="dark"
            style="width: 100%;"
            content="订单中一个或多个核销码被核销的订单总额，叫做有效订单总额"
            placement="bottom"
          >
            <div class="text">有效订单总额 (元)</div>
          </el-tooltip>
        </div>
        <div class="item">
          <bm-tween
            class="number"
            :style="{ color: $store.getters['global/randomColor']() }"
            :value="orderCol.totalCloseOrderCount"
          ></bm-tween>
          <el-tooltip
            class="item"
            effect="dark"
            style="width: 100%;"
            content="该订单已经退款(所有的核销码都已退款)，叫做无效订单总数"
            placement="bottom"
          >
            <div class="text">无效订单总数</div>
          </el-tooltip>
        </div>
        <div class="item">
          <bm-tween
            class="number"
            :style="{ color: $store.getters['global/randomColor']() }"
            :value="orderCol.totalCloseOrderMoney"
          ></bm-tween>
          <el-tooltip
            class="item"
            effect="dark"
            style="width: 100%;"
            content="该订单已经退款(所有的核销码都已退款)的总金额，叫做无效订单总额·"
            placement="bottom"
          >
            <div class="text">无效订单总额 (元)</div>
          </el-tooltip>
        </div>
      </el-card>
      <el-card class="tableContainer" style="display:inline-block;width: 100%;height:350px">
        <div class="title">会员统计</div>
        <div class="item">
          <bm-tween
            class="number"
            :style="{ color: $store.getters['global/randomColor']() }"
            :value="memberCol.totalMemberCount"
          ></bm-tween>
          <div class="text">会员总数</div>
        </div>
        <div class="item">
          <bm-tween
            class="number"
            :style="{ color: $store.getters['global/randomColor']() }"
            :value="memberCol.hasOrderMemberCount"
          ></bm-tween>
          <div class="text">有订单会员数</div>
        </div>
        <div class="item">
          <bm-tween
            class="number"
            :style="{ color: $store.getters['global/randomColor']() }"
            :value="memberCol.memberBuyPercent"
          ></bm-tween>
          <div class="text">平均会员购买率 (%)</div>
        </div>
        <div class="item">
          <bm-tween
            class="number"
            :style="{ color: $store.getters['global/randomColor']() }"
            :value="memberCol.memberOrderPercent"
          ></bm-tween>
          <div class="text">平均会员订单数率 (%)</div>
        </div>
        <div class="item">
          <bm-tween
            class="number"
            :style="{ color: $store.getters['global/randomColor']() }"
            :value="memberCol.memberPayMoneyPercent"
          ></bm-tween>
          <div class="text">平均会员购物额 (元)</div>
        </div>
      </el-card>
    </div>
    <el-card class="tableContainer">
      <div class="title">图表</div>

      <el-row :gutter="20">
        <el-col :span="8">
          <bm-pie class="chart" height="500px" :config="memberBuyPercentConfig" :legendFormat="legendConfig"></bm-pie>
        </el-col>
        <el-col :span="8">
          <bm-pie class="chart" height="500px" :config="memberOrderPercentConfig" :legendFormat="legendConfig">
          </bm-pie>
        </el-col>
        <el-col :span="8">
          <bm-pie
            class="chart"
            height="500px"
            :config="memberPayMoneyPercentConfig"
            :legendFormat="legendConfig"
          ></bm-pie>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      areaId: -1,
      memberBuyPercentConfig: {
        legend: {
          top: '5%',
          left: 'center'
        },
        data: {
          name: '',
          data: []
        }
      },
      memberOrderPercentConfig: {
        data: {
          name: '',
          data: []
        }
      },
      memberPayMoneyPercentConfig: {
        data: {
          name: '',
          data: []
        }
      },

      businessSharingMoneyConfig: {
        data: {
          name: '',
          data: []
        }
      },

      platformSharingMoneyConfig: {
        data: {
          name: '',
          data: []
        }
      },

      allSharingMoneyConfig: {
        data: {
          name: '',
          data: []
        }
      },

      businessSharingMoneyConfig1: {
        data: {
          name: '',
          data: []
        }
      },

      platformSharingMoneyConfig1: {
        data: {
          name: '',
          data: []
        }
      },

      businessFoundConfig: {
        data: {
          name: '',
          data: []
        }
      },

      allSharingMoneyConfig1: {
        data: {
          name: '',
          data: []
        }
      },
      orderCol: {
        memberOrderCount: 0,
        totalSellMoney: 0,
        totalValidOrderCount: 0,
        totalValidOrderMoney: 0,
        totalCloseOrderCount: 0,
        totalCloseOrderMoney: 0
      },
      memberCol: {
        totalMemberCount: 0,
        hasOrderMemberCount: 0,
        memberBuyPercent: 0,
        memberOrderPercent: 0,
        memberPayMoneyPercent: 0
      },
      searchValue: [this.$store.getters['global/day7Int'], this.$store.getters['global/todayInt']],
      list: {
        synthesizeOrderVO: [
          { text: '订单总数', value: 'memberOrderCount', number: 0, tipText: '' },
          { text: '销售总额 (元)', value: 'totalSellMoney', number: 0, tipText: '' },
          {
            text: '有效订单总数',
            value: 'totalValidOrderCount',
            number: 0,
            tipText: '订单中一个或多个核销码被核销，叫做有效订单总数'
          },
          {
            text: '有效订单总额 (元)',
            value: 'totalValidOrderMoney',
            number: 0,
            tipText: '订单中一个或多个核销码被核销的订单总额，叫做有效订单总额'
          },
          {
            text: '无效订单总数',
            value: 'totalCloseOrderCount',
            number: 0,
            tipText: '该订单已经退款(所有的核销码都已退款)，叫做无效订单总数'
          },
          {
            text: '无效订单总额 (元)',
            value: 'totalCloseOrderMoney',
            number: 0,
            tipText: '该订单已经退款(所有的核销码都已退款)的总金额，叫做无效订单总额·'
          }
        ],
        synthesizeMemberVO: [
          { text: '会员总数', value: 'totalMemberCount', number: 0 },
          { text: '有订单会员数', value: 'hasOrderMemberCount', number: 0 },
          { text: '平均会员购买率 (%)', value: 'memberBuyPercent', number: 0 },
          { text: '平均会员订单数率 (%)', value: 'memberOrderPercent', number: 0 },
          { text: '平均会员购物额 (元)', value: 'memberPayMoneyPercent', number: 0 }
        ]
      },
      value: [],
      distribution: { notIncomeYet: 0, readyIncome: 0 },
      loading: false,
      legendConfig: '{name}:{value}',
      moneyLegendConfig: '{name}:{value}',
      moneyLegendConfig10: '{name}:{value}',
      sharingLegendConfig: '{name}:{value}',
      refundData: {}
    }
  },
  created() {
    if (this.$store.getters['user/currentAreaId'] == -1 && this.$store.getters['user/userAreaInfoListWithoutGlobal']) {
      this.areaId = this.$store.getters['user/userAreaInfoListWithoutGlobal'][0].id
    } else {
      this.areaId = sessionStorage.currentAreaId
    }
    this.getList()
    this.getSettlementIncome(0)
    this.getSettlementIncome(1)
    this.refundDataHandle()
    this.getDistributionIncome()
  },

  methods: {
    refundDataHandle() {
      const params = {
        startTime: this.searchValue ? this.searchValue[0] : '',
        endTime: this.searchValue ? this.searchValue[1] : ''
      }
      this.$api.statistics
        .statistics('refundAmountData', {
          params,
          headers: {
            ['Bm-Area-Id']: this.areaId
          }
        })
        .then(res => {
          this.refundData = res
          let mapData = {
            平台退款: res.platformRefundMoney,
            商家退款: res.businessRefundMoney
          }
          this.moneyLegendConfig10 = function(name) {
            return `${name}: ${mapData[name]}`
          }
          this.businessFoundConfig.data.data = []
          this.businessFoundConfig.data.data = []
          let {
            platformRefundMoney,
            businessRefundMoney,
            actualRefundMoney
          } = this.refundData
          console.log(actualRefundMoney)
          this.businessFoundConfig.data.data.push({
            name: '平台退款',
            value: platformRefundMoney
          })
          this.businessFoundConfig.data.data.push({
            name: '商家退款',
            value: businessRefundMoney
          })

          this.businessFoundConfig.data.formatter =
            '退款销售额' +
            '\n' +
            '\n' +
            actualRefundMoney
        })
    },
    handleSearch() {
      const params = {
        startTime: this.searchValue ? this.searchValue[0] : '',
        endTime: this.searchValue ? this.searchValue[1] : ''
      }
      this.getList()
      this.getSettlementIncome(0)
      this.getSettlementIncome(1)
      this.refundDataHandle()

      this.getDistributionIncome()
    },
    getDistributionIncome() {
      const params = {
        startTime: this.searchValue ? this.searchValue[0] : '',
        endTime: this.searchValue ? this.searchValue[1] : ''
      }
      this.$http
        .get('/boom-center-statistics-service/sysAdmin/synthesize/getDistributionIncome', {
          params: this.searchValue ? params : {},
          headers: {
            ['Bm-Area-Id']: this.areaId
          }
        })
        .then(res => {
          if (res) {
            this.distribution = res
          }
        })
        .catch(err => {
          this.$message.error(err.msg)
        })
    },
    getSettlementIncome(status) {
      const params = {
        startTime: this.searchValue ? this.searchValue[0] : '',
        endTime: this.searchValue ? this.searchValue[1] : '',
        status: status
      }
      this.$http
        .get('/boom-center-statistics-service/sysAdmin/synthesize/getSettlementIncome', {
          params,
          headers: {
            ['Bm-Area-Id']: this.areaId
          }
        })
        .then(res => {
          // 先清空数组

          let platformSharingMoneyConfig, businessSharingMoneyConfig, allSharingMoneyConfig
          let allText, platformText, businessText, businessFoundText

          if (status) {
            platformSharingMoneyConfig = this.platformSharingMoneyConfig1
            businessSharingMoneyConfig = this.businessSharingMoneyConfig1
            allSharingMoneyConfig = this.allSharingMoneyConfig1
            this.platformSharingMoneyConfig1.data.data = []
            this.businessSharingMoneyConfig1.data.data = []
            this.allSharingMoneyConfig1.data.data = []
            allText = '销售总额'
            platformText = '平台收销售额'
            businessText = '商家收销售额'
          } else {
            platformSharingMoneyConfig = this.platformSharingMoneyConfig
            businessSharingMoneyConfig = this.businessSharingMoneyConfig
            allSharingMoneyConfig = this.allSharingMoneyConfig
            this.platformSharingMoneyConfig.data.data = []
            this.businessSharingMoneyConfig.data.data = []
            this.allSharingMoneyConfig.data.data = []
            allText = '已核销总额'
            platformText = '平台收已核销金额'
            businessText = '商家收已核销金额'
          }

          platformSharingMoneyConfig.data.data.push({
            name: '平台分成',
            value: res.platformPortionSharingMoney
          })
          platformSharingMoneyConfig.data.data.push({
            name: '平台全收',
            value: res.platformOneHundredPercentSharingMoney
          })

          platformSharingMoneyConfig.data.formatter =
            platformText +
            '\n' +
            '\n' +
            (res.platformOneHundredPercentSharingMoney + res.platformPortionSharingMoney).toFixed(2)

          // 分割线==================
          businessSharingMoneyConfig.data.data.push({
            name: '商家分成收入',
            value: res.businessPortionSharingMoney
          })
          businessSharingMoneyConfig.data.data.push({
            name: '商家全收收入',
            value: res.businessOneHundredPercentSharingMoney
          })

          businessSharingMoneyConfig.data.formatter =
            businessText +
            '\n' +
            '\n' +
            (res.businessPortionSharingMoney + res.businessOneHundredPercentSharingMoney).toFixed(2)

          // 分割线==================

          allSharingMoneyConfig.data.data.push({
            name: '平台收',
            value: res.platformTotalSharingMoney
          })

          allSharingMoneyConfig.data.data.push({
            name: '商家收',
            value: res.businessTotalSharingMoney
          })
          allSharingMoneyConfig.data.formatter =
            allText + '\n' + '\n' + (res.platformTotalSharingMoney + res.businessTotalSharingMoney).toFixed(2)

          let mapData = {
            平台收: res.platformTotalSharingMoney,
            商家收: res.businessTotalSharingMoney,
            平台分成: res.platformPortionSharingMoney,
            平台全收: res.platformOneHundredPercentSharingMoney,
            商家分成收入: res.businessPortionSharingMoney,
            商家全收收入: res.businessOneHundredPercentSharingMoney
          }
          this.moneyLegendConfig = function(name) {
            return `${name}: ${mapData[name]}`
          }
          this.sharingLegendConfig = function(name) {
            return `${name}: ${mapData[name]}`
          }

          if (status) {
            this.platformSharingMoneyConfig1 = platformSharingMoneyConfig
            this.businessSharingMoneyConfig1 = businessSharingMoneyConfig
            this.allSharingMoneyConfig1 = allSharingMoneyConfig
          } else {
            this.platformSharingMoneyConfig = platformSharingMoneyConfig
            this.businessSharingMoneyConfig = businessSharingMoneyConfig
            this.allSharingMoneyConfig = allSharingMoneyConfig
          }
        })
    },
    resetSearch() {
      this.searchValue = []
      this.areaId = -1
      this.handleSearch()
    },
    getList() {
      const params = {
        params: {
          startTime: this.searchValue ? this.searchValue[0] : '',
          endTime: this.searchValue ? this.searchValue[1] : ''
        }
      }
      this.loading = true
      // this.$api.statistics
      //   .statistics('getSynthesize', {
      //     ...params,
      //     headers: {
      //       ['Bm-Area-Id']: this.areaId
      //     }
      //   })
      //   .then(res => {
      //     this.list.synthesizeMemberVO.forEach(item => {
      //       item.number = res.total.synthesizeMemberVO[item.value]
      //     })
      //     this.list.synthesizeOrderVO.forEach(item => {
      //       item.number = res.total.synthesizeOrderVO[item.value]
      //     })
      //     this.list.synthesizeOrderVO[0].number = res.total.synthesizeMemberVO['memberOrderCount']

      //     // 先清空数组
      //     this.memberOrderPercentConfig.data.data = []
      //     this.memberBuyPercentConfig.data.data = []
      //     this.memberPayMoneyPercentConfig.data.data = []

      //     let { hasOrderMemberCount, totalMemberCount } = res.total.synthesizeMemberVO

      //     let {
      //       totalCloseOrderCount,
      //       totalCloseOrderMoney,
      //       totalValidOrderCount,
      //       totalValidOrderMoney
      //     } = res.total.synthesizeOrderVO

      //     this.memberBuyPercentConfig.data.data.push({
      //       name: '有订单会员数',
      //       value: hasOrderMemberCount
      //     })
      //     this.memberBuyPercentConfig.data.data.push({
      //       name: '无订单会员数',
      //       value: totalMemberCount - hasOrderMemberCount
      //     })

      //     this.memberBuyPercentConfig.data.formatter =
      //       '有订单会员 ' + '\n' + '\n' + this.count(hasOrderMemberCount, totalMemberCount - hasOrderMemberCount) + '%'

      //     // ======分割线======
      //     this.memberOrderPercentConfig.data.data.push({
      //       name: '有效订单数',
      //       value: totalValidOrderCount
      //     })

      //     this.memberOrderPercentConfig.data.data.push({
      //       name: '无效订单数',
      //       value: totalCloseOrderCount
      //     })

      //     this.memberOrderPercentConfig.data.formatter =
      //       '有效订单数 ' + '\n' + '\n' + this.count(totalValidOrderCount, totalCloseOrderCount) + '%'

      //     // =====分割线 =============
      //     this.memberPayMoneyPercentConfig.data.data.push({
      //       name: '有效订单总额',
      //       value: totalValidOrderMoney
      //     })
      //     this.memberPayMoneyPercentConfig.data.data.push({
      //       name: '无效订单总额',
      //       value: totalCloseOrderMoney
      //     })

      //     this.memberPayMoneyPercentConfig.data.formatter =
      //       '有效订单总额  ' + '\n' + '\n' + this.count(totalValidOrderMoney, totalCloseOrderMoney) + '%'

      //     let mapData = {
      //       有订单会员数: hasOrderMemberCount,
      //       无订单会员数: totalMemberCount - hasOrderMemberCount,
      //       有效订单数: totalValidOrderCount,
      //       无效订单数: totalCloseOrderCount,
      //       有效订单总额: totalValidOrderMoney,
      //       无效订单总额: totalCloseOrderMoney
      //     }
      //     this.legendConfig = function(name) {
      //       return `${name}: ${mapData[name]}`
      //     }
      //   })
      //   .catch(err => {
      //     console.log(err)
      //     this.$message.error(err.msg || '操作失败')
      //   })
      //   .finally(() => {
      //     this.loading = false
      //   })
      this.$api.statistics
        .statistics('getSynthesize2', {
          ...params,
          headers: {
            ['Bm-Area-Id']: this.areaId
          }
        })
        .then(res => {
          if (res) {
            let { synthesizeOrderVO, synthesizeMemberVO } = res
            this.list.synthesizeMemberVO.forEach(item => {
              item.number = synthesizeMemberVO[item.value]
            })
            this.list.synthesizeOrderVO.forEach(item => {
              item.number = synthesizeOrderVO[item.value]
            })
            this.list.synthesizeOrderVO[0].number = synthesizeMemberVO['memberOrderCount']

            // 先清空数组
            this.memberOrderPercentConfig.data.data = []
            this.memberBuyPercentConfig.data.data = []
            this.memberPayMoneyPercentConfig.data.data = []

            let { hasOrderMemberCount, totalMemberCount } = synthesizeMemberVO

            let {
              totalCloseOrderCount,
              totalCloseOrderMoney,
              totalValidOrderCount,
              totalValidOrderMoney
            } = synthesizeOrderVO

            this.memberBuyPercentConfig.data.data.push({
              name: '有订单会员数',
              value: hasOrderMemberCount
            })
            this.memberBuyPercentConfig.data.data.push({
              name: '无订单会员数',
              value: totalMemberCount - hasOrderMemberCount
            })

            this.memberBuyPercentConfig.data.formatter =
              '有订单会员 ' +
              '\n' +
              '\n' +
              this.count(hasOrderMemberCount, totalMemberCount - hasOrderMemberCount) +
              '%'

            // ======分割线======
            this.memberOrderPercentConfig.data.data.push({
              name: '有效订单数',
              value: totalValidOrderCount
            })

            this.memberOrderPercentConfig.data.data.push({
              name: '无效订单数',
              value: totalCloseOrderCount
            })

            this.memberOrderPercentConfig.data.formatter =
              '有效订单数 ' + '\n' + '\n' + this.count(totalValidOrderCount, totalCloseOrderCount) + '%'

            // =====分割线 =============
            this.memberPayMoneyPercentConfig.data.data.push({
              name: '有效订单总额',
              value: totalValidOrderMoney
            })
            this.memberPayMoneyPercentConfig.data.data.push({
              name: '无效订单总额',
              value: totalCloseOrderMoney
            })

            this.memberPayMoneyPercentConfig.data.formatter =
              '有效订单总额  ' + '\n' + '\n' + this.count(totalValidOrderMoney, totalCloseOrderMoney) + '%'

            let mapData = {
              有订单会员数: hasOrderMemberCount,
              无订单会员数: totalMemberCount - hasOrderMemberCount,
              有效订单数: totalValidOrderCount,
              无效订单数: totalCloseOrderCount,
              有效订单总额: totalValidOrderMoney,
              无效订单总额: totalCloseOrderMoney
            }
            this.legendConfig = function(name) {
              return `${name}: ${mapData[name]}`
            }
            this.orderCol = {
              memberOrderCount: synthesizeMemberVO.memberOrderCount,
              totalSellMoney: synthesizeOrderVO.totalSellMoney,
              totalValidOrderCount: synthesizeOrderVO.totalValidOrderCount,
              totalValidOrderMoney: synthesizeOrderVO.totalValidOrderMoney,
              totalCloseOrderCount: synthesizeOrderVO.totalCloseOrderCount,
              totalCloseOrderMoney: synthesizeOrderVO.totalCloseOrderMoney
            }
            this.memberCol = {
              totalMemberCount: synthesizeMemberVO.totalMemberCount,
              hasOrderMemberCount: synthesizeMemberVO.hasOrderMemberCount,
              memberBuyPercent: synthesizeMemberVO.memberBuyPercent,
              memberOrderPercent: synthesizeMemberVO.memberOrderPercent,
              memberPayMoneyPercent: synthesizeMemberVO.memberPayMoneyPercent
            }
          }
        })
        .catch(err => {
          console.log(err)
          this.$message.error(err.msg || '操作失败')
        })
        .finally(() => {
          this.loading = false
        })
    },
    count(num, num1) {
      let validOrderCount
      if (num !== 0 && num1 === 0) {
        validOrderCount = 100
      } else if (num === 0) {
        validOrderCount = 0
      } else {
        validOrderCount = ((num / (num1 + num)) * 100).toFixed(2)
      }
      return validOrderCount
    }
  }
}
</script>

<style scoped lang="less">
.title {
  margin: 20px;
  font-size: 18px;
}
.chart {
  height: 500px;
  width: 100%;
}
.item {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  width: 33.3%;
  margin-top: 20px;
  .text {
    font-size: 14px;
  }
  .number {
    font-size: 40px;
    color: red;
  }
}
.itemNumber {
  width: 20%;
}

.distribution {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 350px;
  .number {
    margin-bottom: 10px;
    color: red;
    font-size: 40px;
    font-weight: bold;
  }
}
</style>
